<template>
  <div class="div">
    <Navbar />
    <main id="main" class="main">
      <section
        id="Banner"
        class="home-banner-section inner-banner cover-bg"
        style="background-image: url(assets/images/about-us-banner.png)"
      >
        <div class="banner-caption">
          <div class="banner-caption-content text-white">
            <div
              class="
                banner-caption-text
                d-flex
                w-100
                align-items-center
                flex-warp
                h-100
                py-5
              "
            >
              <div class="container">
                <div class="banner-text">
                  <h1 class="title-border">About <span>Us</span></h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="curved-section-main">
        <!-- ! Section start -->
        <section class="section curved-section no-min-height">
          <div class="section-shape section-shape-top">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1920"
              height="84.752"
              viewBox="0 0 1920 84.752"
              class="shape-1"
            >
              <g transform="translate(0 -0.248)">
                <g transform="translate(0 -402)">
                  <path
                    d="M0,439.134q262.562,38.639,458.711,38.638c196.15,0,411.934-35.14,747.269-67.579q335.34-32.44,714.02,67.579V487H0Z"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div class="container section-container-padding">
            <div class="section-top-bar d-flex">
              <div class="section-top-bar-container">
                <h2 class="section-title">About <span>VAXTRAX GLOBAL</span></h2>
              </div>
            </div>
            <p>
              VAXTRAX GLOBAL, is a product of JAB Companies, Inc. (JAB)
              headquartered in Saint Paul Minnesota USA. JAB designs and
              develops products that enhances people lives which provides a
              sustainable lifestyle. VAXTRAX GLOBAL (VTG) has been developed for
              the current COVID virus to provide a quick and simple method of
              displaying your status of COVID tests and vaccines by scanning
              your QR Code. The platform has many unique features
            </p>
            <ul class="custom-list mb-3">
              <li>
                <strong>Get-Together</strong> – Can invite friends and families for
                a cocktail, dinner or coffee and can view their status.
              </li>
              <li>
                <strong>Events- Can be used for events,</strong> Tradeshows, Concerts,
                Stadiums by incorporating the technology into the registration
                process.
              </li>
              <li>
                <strong>Parent/Child</strong> – When traveling or moving around
                with older parents or younger children (under 13), you can tag
                these family members under your profile for one simple scan and
                display all status.
              </li>
              <li>
                <strong>Airline</strong> – VTG is working on integrating the
                airline schedules into the platform so you can add your airline,
                flight numbers and dates. VTG will notify you on your
                requirements for the COVID test and show the countdown display
                of validation or expiration.
              </li>
            </ul>
            <p>
              The platform modules integrate with the following  <br> Labs/Clinics 
              (administering the tests or vaccines) <br> <br> Vendors (scanner of QR
              Codes) e.g. Restaurants, Employers, Airports, Immigration,
              Borders, Schools, Colleges, Universities, Concerts, Stadiums etc.
            </p>
          </div>
        </section>
        <!-- ! Section end -->
      </div>
      <!-- ! Section start -->
      <section
        id="lets_talk"
        class="section curved-section lets-talk-section cover-bg text-white"
      >
        <!--   <section class="section curved-section lets-talk-section cover-bg"> -->
        <div class="container section-container-padding">
          <div class="section-top-bar d-flex">
            <div class="section-top-bar-container">
              <h2 class="section-title title-white">Let's <span>talk</span></h2>
            </div>
          </div>
          <div class="contact-box">
            <form
              action="forms/contact.php"
              method="post"
              role="form"
              class="php-email-form h-100"
            >
              <div class="row">
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="text"
                    name="name"
                    class="form-control"
                    id="name"
                    placeholder="Your Name"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div class="validate"></div>
                </div>
                <div class="col-md-6 form-group mb-4">
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Your Email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div class="validate"></div>
                </div>
              </div>
              <div class="form-group mb-4">
                <input
                  type="text"
                  class="form-control"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  data-rule="minlen:4"
                  data-msg="Please enter at least 8 chars of subject"
                />
                <div class="validate"></div>
              </div>
              <div class="form-group mb-4">
                <textarea
                  class="form-control"
                  name="message"
                  rows="5"
                  data-rule="required"
                  data-msg="Please write something for us"
                  placeholder="Message"
                ></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div class="text-center">
                <button class="btn btn-orange" type="submit">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <!-- ! Section end -->
    </main>
    <!-- ! Main end -->
    <!-- <div class="push"></div> -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar,
  },
  mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/screen.css";

#lets_talk {
  background-image: url("../../assets/images/contact-blockimage.jpg") !important;
}
#Banner {
  background-image: url("../../assets/images/about-us-banner.png") !important;
}
</style>
